import { useEffect, useState } from "react";
import { Tooltip, Select, Pagination, Input, Switch, Button } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FunnelPlotOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import SortingArrow from "../../../Common/SortingArrow";
import DeleteAccountRequest from "../../../Modal/DeleteAccountRequest";
import {
  deleteAffApi,
  getAffiliateListApi,
  updateStatusAffApi,
} from "../../../service/affiliate.service";
import { toast } from "react-toastify";
import { getCountriesApi } from "../../../service/common.service";
import { getCountryName } from "../../../Utils/commonFun";
let timer;
const { Search } = Input;

const AllAffiliates = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [countries, setCountries] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const handleDeleteOk = async () => {
    try {
      if (!selectedId) return;
      setDeleteLoading(true);
      let res = await deleteAffApi(selectedId);
      if (res?.status === 200) {
        getData();
        toast.success(res?.data?.message);
        setIsDeleteModalOpen(false);
        setSelectedId(null);
      } else {
        toast.error(res?.data?.message);
      }
      setDeleteLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setDeleteLoading(false);
    }
  };
  const handleDeleteCancel = () => {
    if (deleteLoading) return;
    setIsDeleteModalOpen(false);
  };
  const filterByStatus = [
    {
      value: "active",
      label: "Active",
    },
    {
      value: "inactive",
      label: "Inactive",
    },
  ];

  const sortFun = (sort, name) => {
    setFilter({ ...filter, sort_field: name, sort_direction: sort });
  };
  const getData = async () => {
    try {
      navigate(
        `?page=${filter?.page ?? ""}&limit=${filter?.limit ?? ""}&sort_field=${
          filter?.sort_field ?? ""
        }&sort_direction=${filter?.sort_direction ?? ""}&status=${
          filter?.status ?? ""
        }&country=${filter?.country ?? ""}&search=${filter?.search ?? ""}`
      );
      setDataLoading(true);
      let res = await getAffiliateListApi(filter);
      setData(res?.data?.data);
      setDataLoading(false);
    } catch (error) {
      console.log(error);
      setDataLoading(false);
    }
  };
  const handleStatusChange = async (status, afData) => {
    try {
      let modifyData = data?.data?.map((d) => {
        if (d?.id == afData?.id) {
          d.status = status ? "active" : "inactive";
        }
        return d;
      });

      setData({ ...data, data: modifyData });
      let res = await updateStatusAffApi(afData?.id, {
        status: status ? "active" : "inactive",
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
      } else {
        let modifyData1 = data?.data?.map((d) => {
          if (d?.id == afData?.id) {
            d.status = status ? "inactive" : "active";
          }
          return d;
        });

        setData({ ...data, data: modifyData1 });
        toast.error(res?.data?.message);
      }
    } catch (error) {
      let modifyData = data?.data?.map((d) => {
        if (d?.id == afData?.id) {
          d.status = status ? "inactive" : "active";
        }
        return d;
      });

      setData({ ...data, data: modifyData });
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getData();
    }, 300);
  }, [filter]);
  useEffect(() => {
    setFilter({
      limit: 10,
      page: searchParams.get("page") ?? "",
      sort_field: searchParams.get("sort_field") ?? "",
      sort_direction: searchParams.get("sort_direction") ?? "",
      status: searchParams.get("status") ? searchParams.get("status") : null,
      search: searchParams.get("search") ?? null,
      country: searchParams.get("country") ? searchParams.get("country") : null,
    });

    setSearchValue(
      searchParams.get("search") ? searchParams.get("search") : null
    );
  }, []);
  const getCountries = async () => {
    try {
      let res = await getCountriesApi();
      if (res?.status === 200) {
        if (Array.isArray(res?.data?.data)) {
          let fCountries = [];
          let usa = null;
          res?.data?.data.forEach((c) => {
            const country = {
              value: c.short_name,
              label: c.name,
              short_name: c.short_name,
              name: c.name,
              // disabled: c.short_name === "us" ? false : true,
            };
            if (c.short_name === "us") {
              usa = country;
            } else {
              fCountries.push(country);
            }
          });
          fCountries.sort((a, b) => a.label.localeCompare(b.label));
          if (usa) {
            fCountries.unshift(usa);
          }
          setCountries(fCountries);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountries();
  }, []);
  console.log(filter);
  return (
    <>
      <div className="flex justify-end gap-3 mb-4">
        <FunnelPlotOutlined />
        <Select
          placeholder="Filter by status"
          options={filterByStatus}
          onChange={(d) => setFilter({ ...filter, status: d, page: 1 })}
          value={filter?.status ?? null}
          style={{
            width: 150,
          }}
        />
        <Select
          placeholder="Filter by country"
          options={countries}
          onChange={(d) => {
            setFilter({ ...filter, country: d, page: 1 });
          }}
          value={filter?.country ?? null}
          style={{
            width: 150,
          }}
        />
        <Search
          placeholder="Search by affiliate name, email address, phone number"
          style={{
            width: 400,
          }}
          value={searchValue ?? null}
          onChange={(e) => {
            if (e.target.value?.length > 2) {
              setFilter({ ...filter, search: e.target.value, page: 1 });
            } else {
              if (e.target.value.length < searchValue?.length) {
                setFilter({ ...filter, search: e.target.value, page: 1 });
              }
            }
            setSearchValue(e.target.value);
          }}
          className="mx-1.5 cursor-pointer"
        />
        <Button
          type="primary"
          onClick={() => {
            setFilter({
              ...filter,
              search: "",
              country: null,
              status: null,
              page: 1,
            });
            setSearchValue("");
          }}
        >
          Clear filter
        </Button>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 min-h-[120px]">
          <thead className="text-xs text-gray-700 bg-gray-100">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Affiliate ID{" "}
                <SortingArrow
                  name="id"
                  onSort={sortFun}
                  crrSort={filter?.sort_field}
                  sortDrr={filter?.sort_direction}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Name{" "}
                <SortingArrow
                  name="name"
                  onSort={sortFun}
                  crrSort={filter?.sort_field}
                  sortDrr={filter?.sort_direction}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Email address{" "}
                <SortingArrow
                  name="email"
                  onSort={sortFun}
                  crrSort={filter?.sort_field}
                  sortDrr={filter?.sort_direction}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Phone number{" "}
                <SortingArrow
                  name="phone"
                  onSort={sortFun}
                  crrSort={filter?.sort_field}
                  sortDrr={filter?.sort_direction}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Country{" "}
                <SortingArrow
                  name="country"
                  onSort={sortFun}
                  crrSort={filter?.sort_field}
                  sortDrr={filter?.sort_direction}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-center whitespace-nowrap"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="relative ">
            {Array.isArray(data?.data) && data?.data?.length ? (
              data?.data?.map((aff) => (
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium cursor-pointer text-gray-900 whitespace-nowrap vertical-top"
                    onClick={() => navigate(`/view-affiliates/${aff?.id}`)}
                  >
                    {aff?.id}
                  </th>
                  <td
                    className="px-3 py-4 vertical-top cursor-pointer font-medium"
                    onClick={() => navigate(`/view-affiliates/${aff?.id}`)}
                  >
                    {aff?.first_name} {aff?.last_name}
                  </td>
                  <td className="px-3 py-4 vertical-top">{aff?.email}</td>
                  <td className="px-3 py-4 vertical-top">{aff?.phone}</td>
                  <td className="px-3 py-4 vertical-top">
                    {getCountryName(countries, aff?.country)}
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    <Switch
                      checked={aff?.status?.toLowerCase() === "active"}
                      onChange={(e) => handleStatusChange(e, aff)}
                    />
                  </td>
                  <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                    <Tooltip title="Edit">
                      <EditOutlined
                        onClick={() => navigate("/edit-affiliates/" + aff?.id)}
                        className="mx-1.5 cursor-pointer"
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <DeleteOutlined
                        onClick={() => {
                          setSelectedId(aff?.id);
                          setIsDeleteModalOpen(true);
                        }}
                        className="mx-1.5 cursor-pointer"
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))
            ) : (
              
              // "Please use the class 'no-record-found' to center the 'No record found' text."
             <div className="no-record-found"><span>{dataLoading ? "Loading..." : "No record found"}</span></div>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="text-base font-semibold">
          Total affiliates- {data?.totalCount ? data?.totalCount : 0}
        </div>
        <Pagination
          defaultCurrent={1}
          onChange={(data) => {
            setFilter({ ...filter, page: data });
          }}
          current={filter?.page}
          total={data?.totalCount}
        />
      </div>
      {/* delete modal */}
      <DeleteAccountRequest
        ConfirmationHeading="Delete affiliate"
        ConfirmationParagraph="Are you sure you want to delete this affiliate?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        loading={deleteLoading}
        onCancel={handleDeleteCancel}
        btnTxt={"Yes"}
        btnTxtNo={"No"}
      />
    </>
  );
};
export default AllAffiliates;
