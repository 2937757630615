import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Typography,
  Row,
  Col,
  Checkbox,
  Select,
  Upload,
  Switch,
  message,
  Image,
} from "antd";
import { LeftOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import ImgCrop from "antd-img-crop";
import validationRule from "../../Utils/validationRule";
import { getCountriesApi } from "../../service/common.service";
import {
  addServiceProviderApi,
  editServiceProviderApi,
  serviceProviderDetailApi,
} from "../../service/sp.service";
import { parsePhoneNumber } from "libphonenumber-js";
import { toast } from "react-toastify";
import { generateUrl } from "../../Utils/commonFun";

const blogCategory = [{ value: "taxation", label: "Taxation" }];
let errorTimeout = null;
let errorTimeout2 = null;
const CreateServiceProvider = () => {
  const params = useParams();
  const isId = params?.id || null;
  const [form] = Form.useForm();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEdit = searchParams.has("edit");
  const isPendingEdit = searchParams.has("invitation-pending-edit");
  const isPendingServiceView = searchParams.has("pending-service-view");
  const { TextArea } = Input;
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [previewImage, setPreviewImage] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isImgErr, setIsImgErr] = useState(false);
  const [docList, setDocList] = useState([]);
  const [fileDelIndex, setFileDelIndex] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    form.setFieldsValue({
      logo: newFileList,
    });
    form.validateFields(["logo"]);
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewOpen(true);
  };

  const beforeUpload = (file) => {
    if (file.size > 10000000) {
      return Upload.LIST_IGNORE;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileList([{ url: reader.result, name: file.name, file: file }]);
      form.setFields([
        {
          name: "logo",
          errors: [],
        },
      ]);
      setIsImgErr(false);
    };
    return false;
  };

  const getCountry = async () => {
    try {
      let res = await getCountriesApi();
      if (res?.status === 200) {
        let cData = [];
        let us = []
        if (Array.isArray(res?.data?.data))
          res?.data?.data?.forEach((element) => {
            if(element.short_name === "us"){
              us.push({
                label: element.name,
                value: element.short_name,
                
              })
            }else{
              cData.push({
                label: element.name,
                value: element.short_name,
                // disabled: element?.short_name === "us" ? false : true,
              });
            }
          });
        setCountries([...us,...cData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitData = async (field) => {
    try {
      if (!fileList.length) {
        setIsImgErr(true);
        return;
      }
      setLoading(true);
      let formData = new FormData();
      let { logo, supporting_documents, item_detail, phone, ...rest } = field;

      const phoneNumber = parsePhoneNumber(phone);
      let countryCode = phoneNumber?.countryCallingCode;
      formData.append("phone", phoneNumber?.nationalNumber);
      formData.append(
        "country_code",
        countryCode.includes("+") ? countryCode : "+" + countryCode
      );
      if (item_detail) {
        formData.append("item_detail", item_detail);
      }
      for (let key in rest) {
        formData.append(key, rest[key]);
      }
      supporting_documents?.forEach((f) => {
        if (f?.originFileObj) {
          formData.append("supporting_documents", f?.originFileObj);
        }
      });
      fileList[0]?.file && formData.append("logo", fileList[0].file);
      let res;
      if (params?.id) {
        if (fileDelIndex?.length) {
          formData.append("supporting_documents", JSON.stringify(fileDelIndex));
        }
        res = await editServiceProviderApi(params?.id, formData);
      } else {
        res = await addServiceProviderApi(formData);
      }
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        navigate(-1);
      } else {
        toast.error(res?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const getData = async () => {
    try {
      let res = await serviceProviderDetailApi(params?.id);
      if (res?.status === 200) {
        let spDoc = res?.data?.data?.supporting_documents;
        let doc = [];

        Array.isArray(spDoc) &&
          spDoc?.forEach((u, i) => {
            doc.push({
              url: generateUrl(u, res?.data?.data?.document_path, params?.id),
              name: u,
              index: i,
            });
          });
        form.setFieldsValue({
          ...res?.data?.data,
        });

        setDocList(doc);
        setFileList([{ url: res?.data?.data?.logo }]);
        if (res?.data?.data?.logo) {
          form.setFields([
            {
              name: "logo",
              errors: [],
            },
          ]);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      console.log(error);
    }
  };
  useEffect(() => {
    getCountry();
    if (params?.id) {
      getData();
    }
  }, []);

  return (
    <Card>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
      <div className="flex items-center justify-between relative mb-4">
        <Typography className="text-xl font-semibold" align={"left"}>
          <LeftOutlined className="mr-2" onClick={() => navigate(-1)} />
          {isId ? "Edit" : "Create"} Service Provider
        </Typography>
        <div className="flex justify-end flex-wrap gap-3 items-center	">
          {isEdit && (
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          )}
          {isPendingEdit && (
            <Button type="primary" onClick={() => navigate("")}>
              Resend password link
            </Button>
          )}
          {isPendingServiceView && (
            <Button type="primary" onClick={() => navigate("")}>
              Resend password link
            </Button>
          )}
        </div>
      </div>
      <Divider />
      <div className="min-h-[calc(100vh_-_305px)]">
        <Form
          layout="vertical"
          style={{
            //   maxWidth: 600,
            width: "100%",
          }}
          form={form}
          initialValues={{
            remember: true,
            country: "us",
            mode_data_fetching: "crm",
          }}
          autoComplete="off"
          onFinish={submitData}
        >
          <Row>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item label="Mode data fetching" name="mode_data_fetching">
                  <Checkbox checked>CRM</Checkbox>
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Service provider "
                  name="sp_name"
                  rules={[
                    {
                      required: true,
                      message: `Please enter Service provider name `,
                    },
                    {
                      max: 100,
                      message: `Service provider name should not be exceed 100 char`,
                    },
                    // {
                    //   pattern: /^[A-Za-z\s]+$/,
                    //   message: `Service provider name can only contain letters and spaces`,
                    // },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length < 3) {
                          return Promise.reject(
                            new Error(
                              `
                                 Service provider name should be minimum 3 characters long.
                              `
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Contact person full name"
                  name="first_name"
                  rules={validationRule.nameRule("Contact person")}
                >
                  <Input size="large" />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Contact person email address"
                  name="email"
                  rules={validationRule.emailRule()}
                >
                  <Input size="large" />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Contact person phone number"
                  name="phone"
                  rules={validationRule.phoneRule()}
                  required
                >
                  <PhoneInput
                    className="border border-[#d9d9d9] rounded-lg	px-[11px] h-[40px]"
                    international={false}
                    defaultCountry="US"
                    countrySelectProps={["US"]}
                    value={value}
                    onChange={setValue}
                    countries={["US"]}
                    addInternationalOption={false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Industry"
                  name="industry"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Industry.",
                    },
                  ]}
                >
                  <Select options={blogCategory} />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="User name or subdomain"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your User name or subdomain.",
                    },
                    {
                      min: 3,
                      message: "Minimum 3 char required",
                    },
                    {
                      max: 100,
                      message:
                        "User name or subdomain not exceed more than 100 char",
                    },
                    {
                      pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/,
                      message: "Enter a valid user name or subdomain",
                    },
                  ]}
                >
                  <Input size="large" readOnly={isId} />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please select Country.",
                    },
                  ]}
                >
                  <Select options={countries} />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  className="upload-sr-provider-image"
                  label="Logo or image"
                  name="logo"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Logo or image.",
                    },
                  ]}
                >
                  <ImgCrop
                    showGrid
                    beforeCrop={(file) => {
                      const isJpgOrPng =
                        file.type === "image/jpeg" || file.type === "image/png";

                      if (!isJpgOrPng) {
                        message.error(`${file.name} is not a JPG/PNG file!`);
                        return false; // Ignore file and prevent upload
                      }
                      if (file.size > 10000000) {
                        message.error(
                          `${file.name} must be smaller than 10MB!`
                        );
                        return false; // Ignore file and prevent upload
                      }
                      return true; // Allow file to be uploaded
                    }}
                    rotationSlider
                    aspectSlider
                    showReset
                    className="w-[180px] h-[180px]"
                  >
                    <Upload
                      // accept="image/png, image/jpeg"
                      multiple={false}
                      listType="picture-card"
                      fileList={fileList}
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      onChange={onChange}
                      onRemove={() => {
                        form.validateFields(["logo"]);
                      }}
                      onPreview={onPreview}
                    >
                      {fileList.length >= 1 ? null : (
                        <Button
                          className="h-[39px]  rounded-lg w-[150px]"
                          icon={<UploadOutlined />}
                        >
                          Click to Upload
                        </Button>
                      )}
                    </Upload>
                  </ImgCrop>
                  {isImgErr ? (
                    <p style={{ color: "#ff4d4f" }}>
                      Please enter Logo or image.
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Business credentials"
                  name="business_credentials"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Business credentials.",
                    },
                    {
                      pattern: "^[a-zA-Z0-9 ]{1,30}$",
                      message: "Please enter valid business credentials",
                    },
                    {
                      min: 3,
                      message: "Min 3 char. required",
                    },
                    {
                      max: 30,
                      message: "Max length 30 char.",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Supporting Documents"
                  name="supporting_documents"
                  rules={[
                    {
                      required: true,
                      message: "Please upload your Supporting Documents.",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={(file) => {
                      if (docList.length >= 10) {
                        clearTimeout(errorTimeout2);
                        errorTimeout2 = setTimeout(() => {
                          message.error("You can only upload up to 10 files.");
                        }, 300);
                        return Upload.LIST_IGNORE;
                      }
                      if (file.type !== "application/pdf") {
                        message.error(`${file.name} is not a PDF file`);
                        return Upload.LIST_IGNORE;
                      }
                      if (file.size > 10 * 1024 * 1024) {
                        message.error(
                          `${file.name} exceeds the size limit of 10 MB`
                        );
                        return Upload.LIST_IGNORE;
                      }
                      return false;
                    }}
                    listType="picture"
                    onRemove={(e) => {
                      const newFileList = docList.filter(
                        (item) => item.uid !== e.uid
                      );
                      setDocList(newFileList);
                      form.setFieldsValue({
                        supporting_documents: newFileList,
                      });
                      form.validateFields(["supporting_documents"]);
                      if (isId) {
                        if (e?.index?.toString()) {
                          setFileDelIndex([...fileDelIndex, e.index]);
                        }
                      }
                    }}
                    multiple={true}
                    accept=".pdf"
                    // maxCount={10}
                    fileList={docList}
                    onChange={(e) => {
                      const currentFileList = e.fileList;
                      // Check if the total number of files exceeds 10
                      if (currentFileList.length > 10) {
                        clearTimeout(errorTimeout);
                        errorTimeout = setTimeout(() => {
                          message.error(
                            "You can only upload only max 10 files."
                          );
                          errorTimeout = null; // Reset the timeout after the message is shown
                        }, 300);
                        const validFileList = currentFileList.slice(0, 10);
                        setDocList(validFileList);
                        form.setFieldsValue({
                          supporting_documents: validFileList,
                        });
                        return;
                      }

                      setDocList(currentFileList);
                      form.setFieldsValue({
                        supporting_documents: currentFileList,
                      });
                      form.validateFields(["supporting_documents"]);
                    }}
                  >
                    <Button
                      className="h-[39px] w-full rounded-lg"
                      icon={<UploadOutlined />}
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Service or product details"
                  name="item_detail"
                >
                  <TextArea rows={4} maxLength={200} showCount />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Divider />
          <div className="flex justify-end gap-3">
            <Button onClick={() => navigate(-1)}>Cancel</Button>

            <Button type="primary" loading={loading} htmlType="submit">
              {isId ? "Save" : "Create"}
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};
export default CreateServiceProvider;
