import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Typography,
  Row,
  Col,
  Select,
  Switch,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useEffect, useState } from "react";
import {
  getAffiliateLisDetail,
  updateAffilateApi,
} from "../../service/affiliate.service";
import { getCountriesApi } from "../../service/common.service";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { toast } from "react-toastify";

const EditAffiliate = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [data, setData] = useState({});
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [form] = Form.useForm();
  const getData = async () => {
    try {
      let res = await getAffiliateLisDetail(params?.id);
      if (res?.status === 200) {
        let resData = res?.data?.data?.affiliateData;
        setData(resData);
        form.setFieldsValue({
          statusBol: resData?.status.toLowerCase() === "active" ? true : false,
          ...resData,
        });
      }
    } catch (error) {
      console.log("Error->>", error);
    }
  };
  const getCountry = async () => {
    try {
      let res = await getCountriesApi();
      if (res?.status === 200) {
        let cData = [];
        let usa = [];
        if (Array.isArray(res?.data?.data))
          res?.data?.data?.forEach((element) => {
            if (element?.short_name === "us") {
              usa.push({
                label: element.name,
                value: element.id,
                disabled: element?.code === "+1" ? false : true,
              });
            } else {
              cData.push({
                label: element.name,
                value: element.id,
                // disabled: element?.code === "+1" ? false : true,
              });
            }
          });
        console.log("usa ->>>", usa);
        setCountries([...usa, ...cData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validatePhoneNumber = (rule, value) => {
    if (!value) {
      return Promise.reject("Please enter your phone number");
    }

    try {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber && isValidPhoneNumber(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject("Please enter a valid phone number");
      }
    } catch (error) {
      return Promise.reject("Please enter a valid phone number");
    }
  };
  useEffect(() => {
    if (params?.id) {
      getData();
    }
    getCountry();
  }, []);

  const handleStatusChange = (checked) => {
    form.setFieldsValue({
      status: checked ? "active" : "inactive",
      statusBol: checked,
    });
  };

  const updateAff = async (data) => {
    try {
      const phoneNumber = parsePhoneNumber(data.phone);
      let countryCode = phoneNumber?.countryCallingCode;

      let obj = {
        first_name: data.first_name,
        last_name: data.last_name,
        phone: phoneNumber?.nationalNumber,
        country_code: countryCode.includes("+")
          ? countryCode
          : "+" + countryCode,
        status: data?.statusBol ? "active" : "inactive",
        email: data?.email,
      };
      setLoading(true);
      let res = await updateAffilateApi(params?.id, obj);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        navigate(-1);
      } else {
        toast.error(res?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Card>
      <Typography className="text-xl font-semibold" align={"left"}>
        <LeftOutlined className="mr-2" onClick={() => navigate(-1)} />
        Edit Affiliate
      </Typography>
      <Divider />
      <div className="min-h-[calc(100vh_-_305px)]">
        <Form
          layout="vertical"
          form={form}
          style={{
            //   maxWidth: 600,
            width: "100%",
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={updateAff}
        >
          <Row>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="First Name "
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name",
                    },
                    {
                      max: 50,
                      message: "First name should not be exceed 50 char.",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Last name can only contain letters and spaces",
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length < 3) {
                          return Promise.reject(
                            new Error(
                              "First name should be minimum 3 characters long."
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Last Name "
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your last name .",
                    },
                    {
                      max: 50,
                      message: "Last name should not be exceed 50 char.",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Last name can only contain letters and spaces",
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length < 3) {
                          return Promise.reject(
                            new Error(
                              "Last name should be minimum 3 characters long."
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </div>
            </Col>

            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Email address.",
                    },
                    {
                      type: "email",
                      message: "Please enter valid Email address.",
                    },
                    {
                      max: 100,
                      message: "Email should not exceed 100 char.",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Phone number"
                  name="phone"
                  rules={[
                    {
                      validator: validatePhoneNumber,
                    },
                  ]}
                >
                  <PhoneInput
                    className="border border-[#d9d9d9] rounded-lg	px-[11px] h-[40px]"
                    international={false}
                    countries={["US"]}
                    defaultCountry="US"
                    value={value}
                    addInternationalOption={false}
                    onChange={setValue}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Country.",
                    },
                  ]}
                >
                  <Select options={countries} size="large" />
                </Form.Item>
              </div>
            </Col>
            <Col span={10}>
              <div className="mr-4">
                <Form.Item
                  label="Status"
                  name="statusBol"
                  valuePropName="checked"
                >
                  <Switch
                    checked={form.statusBol}
                    onChange={handleStatusChange}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Divider />
          <div className="flex justify-end gap-3">
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};
export default EditAffiliate;
