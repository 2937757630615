import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Typography,
  Col,
  Row,
  Switch,
  Divider,
  Tabs,
  Table,
  Tooltip,
  Input,
  Select,
  Button,
  Pagination,
} from "antd";
import {
  LeftOutlined,
  EyeOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
  DownloadOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import ReasonModal from "../../Modal/ReasonModal";
import PendingRequestDetails from "../../Modal/PendingRequestDetails";
import RejectRequestDetail from "../../Modal/RejectRequestDetail";
import {
  deleteOppApi,
  getAssociateAff,
  getOPAssDataApi,
  getOpDetail,
  getOppCommisionApi,
  getPendingAffListApi,
  getStagesListApi,
  pendingAffActionApi,
  pendingAffRejectApi,
  updateMarkPaidApi,
  updateOpStatusApi,
} from "../../service/opportunative.service";
import DeleteAccountRequest from "../../Modal/DeleteAccountRequest";
import { toast } from "react-toastify";
import {
  exportToCsv,
  formatDate,
  getDealDate,
  removeUnderScore,
} from "../../Utils/commonFun";
import BussinessDataTable from "./bussinessDataTable";

const pendingRequestFilter = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
  // {
  //   value: "accepted",
  //   label: "Accepted",
  // },
  // {
  //   value: "deleted",
  //   label: "Deleted",
  // },
];
const { Search } = Input;
let timerAss = null;
const leadAffiliateFilter = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "paid",
    label: "Paid",
  },
];
let timerDeals = null;
let timerLeads = null;
let timerComm = null;
const OpportunityDetail = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [isRejectReasonModalOpen, setIsRejectReasonModalOpen] = useState(false);
  const [isAproveModalOpen, setIsAproveModalOpen] = useState(false);
  const [isRejectRequestModalOpen, setIsRejectRequestModalOpen] =
    useState(false);
  const [data, setData] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pAffData, setPAffData] = useState([]);
  const [asscAffData, setAsscffData] = useState([]);
  const [searchValueAffAss, setSearchValueAffAss] = useState("");
  const [filterAssocData, setFilterAssocData] = useState({
    current: 1,
    pageSize: 10,
  });
  const [pAffALoading, setPAffALoading] = useState(false);
  const [pAffVError, setPAffVError] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [pAff, setPAff] = useState({});
  const [filterPendingData, setFilterPendingData] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filterDealsData, setFilterDealsData] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filterLeadsData, setFilterLeadsData] = useState({
    current: 1,
    pageSize: 10,
  });
  const [pAffLoading, setPAffLoading] = useState(false);
  const [dealsColumns, setDealsColumns] = useState([]);
  const [leadsColumns, setLeadsColumns] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [dealsData, setDealsData] = useState([]);
  const [leadsStages, setLeadsStages] = useState([]);
  const [dealsStages, setDealsStages] = useState([]);
  const [commisionData, setCommisionData] = useState([]);
  const [filterCommisionData, setFilterCommisionData] = useState({
    current: 1,
    pageSize: 10,
  });
  const params = useParams();
  let id = params?.id;

  const getCommissionData = async () => {
    try {
      let res = await getOppCommisionApi(id, filterCommisionData);

      if (res?.status === 200) {
        setCommisionData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    clearInterval(timerComm);
    timerComm = setTimeout(() => {
      getCommissionData();
    }, 300);
  }, [filterCommisionData]);
  const getLeadsList = async () => {
    try {
      let res = await getOPAssDataApi(id, "prospect", filterLeadsData);

      if (res?.status === 200) {
        if (
          Array.isArray(res?.data?.data?.column_keys) &&
          res?.data?.data?.column_keys?.length > 0
        ) {
          setLeadsColumns(res?.data?.data?.column_keys);
        }

        setLeadsData({
          data: res?.data?.data?.data,
          totalCount: res?.data?.data?.totalCount,
        });
      }
    } catch (error) {}
  };
  const getDealsList = async () => {
    try {
      let res = await getOPAssDataApi(id, "deal", filterDealsData);

      if (res?.status === 200) {
        if (
          Array.isArray(res?.data?.data?.column_keys) &&
          res?.data?.data?.column_keys?.length > 0
        ) {
          setDealsColumns(res?.data?.data?.column_keys);
        }

        setDealsData({
          data: res?.data?.data?.data,
          totalCount: res?.data?.data?.totalCount,
        });
      }
    } catch (error) {}
  };
  useEffect(() => {
    clearTimeout(timerLeads);
    timerLeads = setTimeout(() => {
      getLeadsList();
    }, 300);
  }, [filterLeadsData]);

  useEffect(() => {
    clearTimeout(timerDeals);
    timerDeals = setTimeout(() => {
      getDealsList();
    }, 300);
  }, [filterDealsData]);
  const handleRejectReasonModalOk = async () => {
    try {
      setPAffLoading(true);
      let res = await pendingAffActionApi(pAff?.id, {
        status: "rejected",
        reject_reason: rejectReason,
      });
      if (res?.status === 200) {
        setIsRejectReasonModalOpen(false);
        getPendingRequestAff();
        setPAff({});
        setRejectReason("");
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
      setPAffLoading(false);
    } catch (error) {
      setPAffLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const tabChange = (key) => {};
  const handleAproveModalOk = async () => {
    try {
      setPAffALoading(true);
      let res = await pendingAffActionApi(pAff?.id, {
        status: "accepted",
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        setIsAproveModalOpen(false);
        getAssocAff();
        getPendingRequestAff();
        setPAff({});
      } else {
        toast.error(res?.data?.message);
      }
      setPAffALoading(false);
    } catch (error) {
      setPAffALoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const handleAproveModalCancel = () => {
    setIsAproveModalOpen(false);
  };
  const handleRejectRequestModalOk = () => {
    try {
      if (!pAff?.id) return;
      setIsRejectRequestModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRejectRequestModalCancel = () => {
    setIsRejectRequestModalOpen(false);
  };
  const getStages = async () => {
    try {
      let res = await getStagesListApi(id);
      if (res?.status === 200) {
        let sData = res?.data?.data;
        let leadsList = Array.isArray(sData)
          ? sData?.filter((val) => val.type === "prospect")
          : [];
        let dealsList = Array.isArray(sData)
          ? sData?.filter((val) => val.type === "deal")
          : [];
        setDealsStages(dealsList);
        setLeadsStages(leadsList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStages();
  }, []);

  const affiliatesAssociatedColumns = [
    {
      title: "Affiliate name",
      dataIndex: "user",
      key: "first_name",
      render: (user) => user?.first_name + " " + user?.last_name,
    },
    {
      title: "ID",
      dataIndex: "user_id",
      key: "id",
      render: (id) => (
        <Link className="link" to={`/view-affiliates/${id}`}>
          #{id}
        </Link>
      ),
    },
    {
      title: "Affiliate status",
      dataIndex: "user",
      key: "status",
      render: (user) => <p className="capitalize">{user?.status}</p>,
    },
    {
      title: "Email address",
      dataIndex: "user",
      key: "email",
      render: (user) => user?.email,
    },
    {
      title: " Enrolled date",
      dataIndex: "created_at",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  const pendingRequestsColumns = [
    {
      title: "Affiliate name",
      dataIndex: "user",
      key: "name",
      render: (user) => user?.first_name + " " + user?.last_name,
    },
    {
      title: "ID",
      dataIndex: "user_id",
      key: "id",
      render: (id) => (
        <Link className="link" to={`/view-affiliates/${id}`}>
          #{id}
        </Link>
      ),
    },
    {
      title: "Affiliate status",
      dataIndex: "user",
      key: "status",
      render: (user) => <p className="capitalize"> {user.status}</p>,
    },
    {
      title: "Email address",
      dataIndex: "user",
      key: "email",
      render: (user) => user?.email,
    },
    {
      title: "Phone number",
      dataIndex: "user",
      key: "phone",
      render: (user) => user?.phone,
    },
    {
      title: "Requested  date",
      dataIndex: "created_at",
      key: "date",
      render: (createdAt) => formatDate(createdAt),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span class="py-2 px-3 bg-slate-100 text-black text-xs rounded-lg capitalize">
          {status}
        </span>
      ),
    },
    {
      title: "Action",
      // dataIndex: "data",
      key: "action",
      render: (data) => (
        <span>
          <Tooltip title="Approve">
            <CheckSquareOutlined
              onClick={() => {
                setPAff(data);
                setIsAproveModalOpen(true);
              }}
              className="mx-1.5 cursor-pointer"
            />
          </Tooltip>
          {data?.status !== "rejected" && (
            <Tooltip title="Reject">
              <CloseSquareOutlined
                onClick={() => {
                  setPAff(data);
                  setIsRejectReasonModalOpen(true);
                }}
                className="mx-1.5 cursor-pointer"
              />
            </Tooltip>
          )}
        </span>
      ),
    },
  ];
  const markPaid = async (data) => {
    try {
      let changeData = commisionData?.data?.map((item) => {
        if (item?.id === data?.id) {
          return {
            ...item,
            status: item?.status === "paid" ? "pending" : "paid",
          };
        }
        return item;
      });
      setCommisionData({ ...commisionData, data: changeData });
      let res = await updateMarkPaidApi(data?.id, {
        status: data?.status === "paid" ? "pending" : "paid",
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        getCommissionData();
      } else {
        toast.error(res?.data?.message);
        let changeData = commisionData?.data?.map((item) => {
          if (item?.id === data?.id) {
            return {
              ...item,
              status: item?.status === "paid" ? "pending" : "paid",
            };
          }
          return item;
        });
        setCommisionData({ ...commisionData, data: changeData });
      }
    } catch (error) {
      let changeData = commisionData?.data?.map((item) => {
        if (item?.id === data?.id) {
          return {
            ...item,
            status: item?.status === "paid" ? "pending" : "paid",
          };
        }
        return item;
      });

      setCommisionData({ ...commisionData, data: changeData });
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const handlePaginationAssAf = (pagination) => {
    setFilterAssocData({ ...filterAssocData, ...pagination });
  };
  const commissionReportColumns = [
    {
      title: "Affiliate",
      dataIndex: "user",
      key: "name",
      render: (user) => <p>{user?.first_name + " " + user?.last_name}</p>,
    },
    {
      title: "Affiliate status",
      dataIndex: "user",
      key: "status",
      render: (user) => <p className="capitalize">{user?.status}</p>,
    },
    {
      title: "Email address",
      dataIndex: "user",
      key: "emailAddress",
      render: (user) => <p>{user?.email}</p>,
    },
    {
      title: "Lead ID",
      dataIndex: "customer",
      key: "customer_id",
      render: (customer) => <p>{customer?.customer_id}</p>,
    },
    {
      title: "Lead date",
      dataIndex: "customer",
      key: "leadDate",
      render: (customer) => formatDate(customer?.created_at),
    },
    {
      title: "Deal close on ",
      dataIndex: "customer",
      key: "dealCloseDate",
      render: (customer) => {
        return getDealDate(customer?.stage, customer?.stage_timeline);
      },
    },
    {
      title: "Platform Commission %",
      dataIndex: "customer",
      key: "commission",
      render: (customer) => {
        return customer?.platform_commission_per + "%";
      },
    },
    {
      title: "Platform Commission Amount",
      dataIndex: "customer",
      key: "commissionAmount",
      render: (customer) => {
        return "$" + customer?.platform_commission_amount;
      },
    },
    {
      title: "Affiliate Commission %",
      dataIndex: "customer",
      key: "commission",
      render: (customer) => {
        return customer?.affiliate_commission_per + "%";
      },
    },
    {
      title: "Affiliate Commission Amount",
      dataIndex: "customer",
      key: "commissionAmount",
      render: (customer) => {
        return "$" + customer?.affiliate_commission_amount;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span class="py-2 px-3 bg-slate-100 text-black text-xs rounded-lg capitalize">
          {status}
        </span>
      ),
    },
    {
      title: "Action",

      key: "action",
      render: (data) => (
        <Tooltip title="Mark as paid">
          <FileDoneOutlined
            style={{ color: data?.status === "paid" ? "green" : "black" }}
            onClick={() => markPaid(data)}
            className="cursor-pointer"
          />
        </Tooltip>
      ),
    },
  ];
  const formatDataExport = (data, type) => {
    debugger
    let getStage = (slug, json) => {
      let stage = json?.find((item) => item?.slug === slug);
      if (stage) {
        return stage?.key + " " + stage?.value;
      }
      return "-";
    };
    let allData = data?.data;
    let columns = data?.column_keys;
    if (!allData?.length) {
      let colValuesBlank = {};
      let condValueBlank = {};
      columns?.forEach((col) => {
        colValuesBlank[removeUnderScore(col)] = "";
      });
      if (type === "deals") {
        condValueBlank["Total deal amount"] = "";
        condValueBlank["Platform commission (%)"] = "";
        condValueBlank["Platform commission ($)"] = "";
        condValueBlank["Affiliate commission (%)"] = "";
        condValueBlank["Affiliate commission ($)"] = "";
      } else {
        condValueBlank["Potential commission"] = "";
      }
      return [
        {
          Affiliate: "",
          "Affiliate email": "",
          "Affiliate status": "",
          Source: "",
          ...colValuesBlank,
          "Status & Timeline": "",
          "Last updated date & time": "",
          ...condValueBlank,
        },
      ];
    }
    return allData?.map((item) => {
      let colValues = {};
      let condValue = {};
      columns?.forEach((col) => {
        colValues[removeUnderScore(col)] = item?.customer_detail_json?.[col];
      });
      if (type === "deals") {
        condValue["Total deal amount"] = "$" + item?.total_deal_amount;
        condValue["Platform commission (%)"] =
          item?.platform_commission_per + "%";
        condValue["Platform commission ($)"] =
          "$" + item?.platform_commission_amount;
        condValue["Affiliate commission (%)"] =
          item?.affiliate_commission_per + "%";
        condValue["Affiliate commission ($)"] =
          "$" + item?.affiliate_commission_amount;
      } else {
        condValue["Potential commission"] =
          item?.potential_commission_per + "%";
      }
      debugger
      return {
        // ID: item?.customer_id,
        Affiliate: item?.user?.first_name + " " + item?.user?.last_name,
        "Affiliate email": item?.user?.email,
        "Affiliate status": item?.user?.status,
        Source: item?.source,
        ...colValues,
        "Status & Timeline": getStage(item?.stage, item?.stage_timeline),
        "Last updated date & time": formatDate(item?.last_updated_date, "dt"),
        ...condValue,
      };
    });
  };
  const exportCsvLeads = async () => {
    try {
      let res = await getOPAssDataApi(id, "prospect", {
        ...filterLeadsData,
        pageSize: 10000,
        page: 1,
      });

      let finalData = formatDataExport(res?.data?.data, "prospects");

      exportToCsv(finalData, "Leads Data");
    } catch (error) {}
  };
  const exportCsvDeals = async () => {
    try {
      let res = await getOPAssDataApi(id, "deal", {
        ...filterDealsData,
        pageSize: 10000,
        page: 1,
      });

      let finalData = formatDataExport(res?.data?.data, "deals");

      exportToCsv(finalData, "Deals Data");
    } catch (error) {}
  };
  const opportunityDetailTable = [
    {
      key: "1",
      label: "Affiliates associated",
      children: (
        <div className="">
          <div className="flex justify-end mb-4">
            <Search
              placeholder="Search by affiliate name"
              style={{
                width: 250,
              }}
              value={searchValueAffAss}
              onChange={(e) => {
                if (e.target.value?.length > 2) {
                  setFilterAssocData({
                    ...filterAssocData,
                    search: e.target.value,
                    current: 1,
                  });
                } else {
                  if (e.target.value.length < searchValueAffAss?.length) {
                    setFilterAssocData({
                      ...filterAssocData,
                      search: e.target.value,
                      current: 1,
                    });
                  }
                }
                setSearchValueAffAss(e.target.value);
              }}
              className="mx-1.5 cursor-pointer"
            />
            <Button
              type="primary"
              onClick={() => {
                setFilterAssocData({ ...asscAffData, search: "", current: 1 });
                setSearchValueAffAss("");
              }}
            >
              Clear filter
            </Button>
          </div>
          <div className="w-full overflow-x-auto overflow-y-hidden">
            <Table
              className="table-auto  min-w-[1140px]"
              columns={affiliatesAssociatedColumns}
              dataSource={asscAffData?.data}
              rowClassName={() => "no-hover"}
              // pagination={{
              //   current: filterAssocData?.current,
              //   pageSize: filterAssocData?.pageSize,
              //   total: asscAffData?.totalCount,
              // }}
              // onChange={handlePaginationAssAf}
              pagination={false}
            />
          </div>
          <div className="flex justify-end mt-2">
            <Pagination
              current={filterAssocData?.current}
              pageSize={filterAssocData?.pageSize}
              total={asscAffData?.totalCount}
              onChange={handlePaginationAssAf}
            />
          </div>
          <div className="text-base font-semibold">
            Total :- {asscAffData?.totalCount ? asscAffData?.totalCount : 0}
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Pending Requests",
      children: (
        <div>
          <div className="flex justify-end gap-2 mb-4">
            <FilterOutlined className="mr-2" />
            <Select
              placeholder="Filter by status"
              options={pendingRequestFilter}
              onChange={(data) =>
                setFilterPendingData({ ...filterPendingData, status: data })
              }
              value={filterPendingData?.status}
              style={{
                width: 150,
              }}
            />
            <Button
              type="primary"
              onClick={() =>
                setFilterPendingData({
                  ...filterPendingData,
                  status: null,
                  current: 1,
                })
              }
            >
              Clear filter
            </Button>
          </div>
          <div className="w-full overflow-x-auto overflow-y-hidden">
            <Table
              className="table-auto  min-w-[1140px]"
              columns={pendingRequestsColumns}
              dataSource={pAffData?.data}
              rowClassName={() => "no-hover"}
              pagination={false}
            />
          </div>
          <div className="flex justify-end mt-2">
            <Pagination
              current={filterPendingData?.current}
              pageSize={filterPendingData?.pageSize}
              total={pAffData?.totalCount}
              onChange={handlePaginationAssAf}
            />
          </div>
          <div className="text-base font-semibold">
            Total :- {pAffData?.totalCount ? pAffData?.totalCount : 0}
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Leads",
      children: (
        <BussinessDataTable
          columns={leadsColumns}
          data={leadsData}
          stages={leadsStages}
          setFilter={setFilterLeadsData}
          filter={filterLeadsData}
          exportCsv={exportCsvLeads}
          type="leads"
          id={id}
        />
      ),
    },
    {
      key: "4",
      label: "All deals",
      children: (
        <BussinessDataTable
          columns={dealsColumns}
          data={dealsData}
          stages={dealsStages}
          setFilter={setFilterDealsData}
          filter={filterDealsData}
          type="deals"
          exportCsv={exportCsvDeals}
          id={id}
        />
      ),
    },
    {
      key: "5",
      label: "Commission Report",
      children: (
        <div>
          <div className="flex justify-end mb-4 gap-x-2.5	items-center">
            <FilterOutlined className="" />
            <Select
              size="sm"
              placeholder="Filter by status"
              options={leadAffiliateFilter}
              style={{
                width: 200,
              }}
              onChange={(data) =>
                setFilterCommisionData({
                  ...filterCommisionData,
                  status: data,
                  page: 1,
                })
              }
              value={filterCommisionData?.status}
            />
            <Button
              type="primary"
              onClick={() =>
                setFilterCommisionData({
                  ...filterCommisionData,
                  status: null,
                  page: 1,
                })
              }
            >
              Clear filter
            </Button>
            <p class="py-2 px-3 bg-slate-100 text-black text-sm rounded-lg">
              Total Deals Closed -{" "}
              {commisionData?.total_deal ? commisionData?.total_deal : 0}
            </p>
            <p class="py-2 px-3 bg-slate-100 text-black text-sm rounded-lg">
              Total Commission Amount -{" "}
              {commisionData?.total_affiliate_commission_amount
                ? commisionData?.total_affiliate_commission_amount
                : 0}
            </p>
          </div>
          <div className="w-full overflow-x-auto overflow-y-hidden">
            <Table
              className="table-auto  min-w-[1140px]"
              columns={commissionReportColumns}
              dataSource={commisionData?.data}
              pagination={{
                current: filterCommisionData?.page,
                onChange: (page) => {
                  setFilterCommisionData({
                    ...filterCommisionData,
                    page: page,
                  });
                },
                pageSize: 10,
                total: commisionData?.totalCount,
              }}
            />
          </div>
          <div className="text-base font-semibold">
            Total :- {commisionData?.totalCount ? commisionData?.totalCount : 0}
          </div>
        </div>
      ),
    },
  ];

  const getData = async () => {
    try {
      let res = await getOpDetail(id);
      if (res?.status === 200) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dPopupClose = () => {
    setIsDelete(false);
  };
  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      let res = await deleteOppApi(id);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        navigate(-1);
      } else {
        toast.error(res?.data?.message);
      }
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const getAssocAff = async () => {
    try {
      let res = await getAssociateAff(id, filterAssocData);
      setAsscffData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getPendingRequestAff = async () => {
    try {
      let res = await getPendingAffListApi(id, filterPendingData);
      setPAffData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatus = async (checked) => {
    try {
      setData({ ...data, status: checked ? "active" : "inactive" });
      let res = await updateOpStatusApi(id, {
        status: checked ? "active" : "inactive",
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);

        setData({ ...data, status: checked ? "inactive" : "active" });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setData({ ...data, status: checked ? "inactive" : "active" });
    }
  };
  const closeReasonModel = () => {
    setRejectReason("");
    setIsRejectReasonModalOpen(false);
  };
  useEffect(() => {
    clearTimeout(timerAss);
    timerAss = setTimeout(() => {
      getAssocAff();
    }, 200);
  }, [filterAssocData]);

  useEffect(() => {
    getPendingRequestAff();
  }, [filterPendingData]);

  return (
    <>
      <Card className="opportunityDetail">
        <div className="flex items-center justify-between relative mb-4">
          <Typography className="text-xl font-semibold mb-10" align={"left"}>
            <LeftOutlined className="mr-2 " onClick={() => navigate(-1)} />
            Opportunity detail
          </Typography>
          <div className="flex justify-end flex-wrap gap-3 items-center	">
            <span class="py-2 px-3 bg-slate-100 text-black text-sm rounded-lg">
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={handleStatus}
                checked={data?.status?.toLowerCase() === "active"}
              />
            </span>
            <Button
              type="primary"
              onClick={() => navigate(`/edit-opportunity/${id}`)}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => setIsDelete(true)}
            >
              Delete
            </Button>
          </div>
        </div>
        <div>
          <Row gutter={[20, 20]}>
            <Col span={6}>Opportunity ID</Col>
            <Col span={18}>#{data?.id}</Col>
            <Col span={6}>Opportunity name</Col>
            <Col span={18}>{data?.opportunity_name}</Col>
            <Col span={6}>Description</Col>
            <Col span={18}>
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </Col>
            <Col span={6}>Image</Col>
            <Col span={18}>
              <div className="">
                <img src={data?.image} className="w-16 h-16" alt="" />
              </div>
            </Col>
            <Col span={6}>Is profile review required?</Col>
            <Col span={18}>
              <Switch
                disabled={disabled}
                checked={data?.profile_review_required}
              />
            </Col>
            <Col span={6}>Agreement of opportunity</Col>
            <Col span={18}>
              <p className="w-[70%]">
                <div dangerouslySetInnerHTML={{ __html: data?.agreement }} />
              </p>
            </Col>
            <Col span={6}>Summary of opportunity</Col>
            <Col span={18}>
              <p className="w-[70%]">
                <div dangerouslySetInnerHTML={{ __html: data?.summary }} />
              </p>
            </Col>
            <Col span={6}>Creator name</Col>
            <Col span={18}>{data?.creator_name} </Col>
            <Col span={6}>Created date</Col>
            <Col span={18}>{formatDate(data?.created_at)} </Col>
            <Col span={6}>Platform commission</Col>
            <Col span={18}>{data?.platform_commission}%</Col>
            <Col span={6}>Affiliate commission</Col>
            <Col span={18}>{data?.commission_percentage}%</Col>
            <Col span={6}>Calendly event url</Col>
            <Col span={18}>{data?.event_url ? data?.event_url : "-"}</Col>
          </Row>
          <Divider />
          <h3 className="text-base	font-semibold	mb-4">
            Service Provider details
          </h3>
          <Row gutter={[20, 20]}>
            <Col span={6}>Service Provider ID</Col>
            <Col span={18}>
              <Link
                className="link"
                to={`/view-service-providers/${data?.user_id}`}
              >
                #{data?.user_id}{" "}
              </Link>
            </Col>
            <Col span={6}>Name</Col>
            <Col span={18}>{data?.user?.first_name}</Col>
            <Col span={6}>Email address</Col>
            <Col span={18}>{data?.user?.email}</Col>
          </Row>
          <Divider />
          <h3 className="text-base	font-semibold	mb-4 ">Landing Page</h3>
          <Row gutter={[20, 20]}>
            <Col span={6}>Landing page</Col>
            <Col span={18}>
              <Link
                className="link"
                target="_blank"
                to={data?.template_html_link}
              >
                {data?.template_html_link}
              </Link>
            </Col>
          </Row>
          <Divider />
          <div>
            <Tabs
              defaultActiveKey="1"
              items={opportunityDetailTable}
              onChange={tabChange}
            />
          </div>
        </div>
      </Card>
      <DeleteAccountRequest
        isOpen={isDelete}
        btnTxt={"Delete"}
        ConfirmationHeading={"Delete opportunity"}
        ConfirmationParagraph={"Do you want to delete this opportunity?"}
        onCancel={dPopupClose}
        loading={deleteLoading}
        onOk={handleDelete}
      />
      <ReasonModal
        ReasonText="Specify your reason"
        ReasonPlaceholder="Enter your reason here"
        isOpen={isRejectReasonModalOpen}
        onOk={handleRejectReasonModalOk}
        onCancel={closeReasonModel}
        validationError={pAffVError}
        rejectReason={rejectReason}
        loading={pAffLoading}
        setRejectReason={setRejectReason}
      />
      <PendingRequestDetails
        ReasonText="Request detail"
        isOpen={isAproveModalOpen}
        onOk={handleAproveModalOk}
        onCancel={handleAproveModalCancel}
        data={pAff}
        loading={pAffALoading}
      />
      <RejectRequestDetail
        ReasonText="Request detail"
        isOpen={isRejectRequestModalOpen}
        onOk={handleRejectRequestModalOk}
        onCancel={handleRejectRequestModalCancel}
      />
    </>
  );
};
export default OpportunityDetail;
