import { Button, Input, Pagination } from "antd";
import SortingArrow from "../../../Common/SortingArrow";
import { formatDate } from "../../../Utils/commonFun";
import EmptyComponent from "../../../Component/Empty";
import { Link } from "react-router-dom";
const { Search } = Input;

const Opportunity = ({ data, filter, setFilter, search, setSearch }) => {
  return (
    <>
      <div className="flex justify-end gap-3  mb-4">
        <Search
          placeholder="Search by opportunity name"
          style={{
            width: 250,
          }}
          onChange={(e) => {
            let value = e.target.value;
            if (value?.length > 2 || value?.length < search?.length) {
              setFilter({ ...filter, search: value, page: 1 });
            }

            setSearch(value);
          }}
          value={search}
          className="mx-1.5 cursor-pointer"
        />
        <Button
          type="primary"
          onClick={() => {
            setFilter({ ...filter, search: "", page: 1 });
            setSearch("");
          }}
        >
          Clear filter
        </Button>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 bg-gray-100">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                ID
              </th>
              <th scope="col" className="px-3 py-3">
                Opportunity Name{" "}
              </th>
              <th scope="col" className="px-3 py-3">
                Service provider
              </th>
              <th scope="col" className="px-3 py-3">
                Enrolled Date
              </th>
              <th scope="col" className="px-3 py-3">
                Leads Generated
              </th>
              <th scope="col" className="px-3 py-3">
                Deals closed
              </th>
              <th scope="col" className="px-3 py-3">
                Commission %
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data?.rows) ? (
              data?.rows?.length > 0 ? (
                data?.rows?.map((op, index) => {
                  return (
                    <tr className="bg-white border-b" key={index}>
                      <th
                        scope="row"
                        className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                      >
                        <Link
                          to={`/opportunity-details/${op?.opportunity?.id}`}
                        >
                          {op?.opportunity?.id}
                        </Link>
                      </th>
                      <td className="px-3 py-4 vertical-top">
                        {op?.opportunity?.opportunity_name}
                      </td>
                      <td className="px-3 py-4 vertical-top">
                        {op?.opportunity?.user?.first_name}
                      </td>
                      <td className="px-3 py-4 vertical-top">
                        {formatDate(op?.enrolled_date)}
                      </td>
                      <td className="px-3 py-4 vertical-top">
                        {op?.opportunity?.lead_generated
                          ? op?.opportunity?.lead_generated
                          : "-"}
                      </td>
                      <td className="px-3 py-4 vertical-top">
                        {op?.opportunity?.deals_closed
                          ? op?.opportunity?.deals_closed
                          : "-"}
                      </td>
                      <td className="px-3 py-4 vertical-top">
                        {op?.opportunity?.commission_percentage
                          ? op?.opportunity?.commission_percentage + "%"
                          : "-"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    <EmptyComponent />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  <EmptyComponent />
                </td>
              </tr>
            )}
            {/* <tr className="bg-white border-b">
              <th
                scope="row"
                className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top"
              >
                1234
              </th>
              <td className="px-3 py-4 vertical-top">Wellness</td>
              <td className="px-3 py-4 vertical-top">IHub</td>
              <td className="px-3 py-4 vertical-top">17-06-2024</td>
              <td className="px-3 py-4 vertical-top">15</td>
              <td className="px-3 py-4 vertical-top">3</td>
              <td className="px-3 py-4 vertical-top">20%</td>
            </tr>
            <tr className="bg-white border-b">
              <th
                scope="row"
                className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top"
              >
                1234
              </th>
              <td className="px-3 py-4 vertical-top">Wellness</td>
              <td className="px-3 py-4 vertical-top">IHub</td>
              <td className="px-3 py-4 vertical-top">17-06-2024</td>
              <td className="px-3 py-4 vertical-top">15</td>
              <td className="px-3 py-4 vertical-top">3</td>
              <td className="px-3 py-4 vertical-top">20%</td>
            </tr>
            <tr className="bg-white border-b">
              <th
                scope="row"
                className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top"
              >
                1234
              </th>
              <td className="px-3 py-4 vertical-top">Wellness</td>
              <td className="px-3 py-4 vertical-top">IHub</td>
              <td className="px-3 py-4 vertical-top">17-06-2024</td>
              <td className="px-3 py-4 vertical-top">15</td>
              <td className="px-3 py-4 vertical-top">3</td>
              <td className="px-3 py-4 vertical-top">20%</td>
            </tr> */}
          </tbody>
        </table>
        <Pagination
          total={data?.totalCount}
          current={filter?.page}
          onChange={(page) => {
            setFilter({ ...filter, page: page });
          }}
        />
      </div>
    </>
  );
};
export default Opportunity;
