import {
  Typography,
  Button,
  Input,
  Form,
  Layout,
  Card,
  Flex,
  Col,
  Row,
} from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { resetPasswordApi } from "../../../service/auth.service";
import { useNavigate, useParams } from "react-router-dom";
import Password from "antd/es/input/Password";
const { Title } = Typography;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const resetPassword = async (e) => {
    try {
      setLoading(true);
      if (!params?.token) return;
      let res = await resetPasswordApi({
        token: params?.token,
        password: e?.password,
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        navigate("/");
      } else {
        toast.error(res?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  return (
    <>
      <Layout>
        <Row>
          <Col span={12}>
            <div
              className="relative h-screen bg-cover	w-full bg-no-repeat	bg-bottom	flex justify-end"
              style={{
                backgroundImage: `url("login-banner.jpg")`,
              }}
            >
              <div className=" bg-black/40 shadow-md rounded-md	 flex justify-end items-end	 p-16">
                <p className="text-white text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <Flex
              gap="middle"
              align="center"
              justify="center"
              vertical
              style={{ minHeight: "100vh" }}
            >
              <Card className="relative">
                <Title level={3} align={"center"}>
                  Reset Password
                </Title>
                <Form
                  name="login"
                  layout="vertical"
                  style={{
                    maxWidth: 400,
                    minWidth: 400,
                    marginTop: 24,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  autoComplete="off"
                  onFinish={resetPassword}
                >
                  <Form.Item
                    label="New password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your New password",
                      },
                      {
                        min: 10,
                        message: "Password should be minimum 10 char long",
                      },
                      {
                        max: 50,
                        message:
                          "Password should not be exceed more than 50 char long",
                      },
                      {
                        pattern: /[A-Za-z]/,
                        message: "Password must contain at least one letter!",
                      },
                      {
                        pattern: /\d/,
                        message: "Password must contain at least one number",
                      },
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Confirm new password"
                    name="confirmNewPassword"
                    dependencies={["newPassword"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your confirm new password",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Passwords do NOT match"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                  <Button
                    className="w-full mt-3"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Change Password
                  </Button>
                </Form>
              </Card>
            </Flex>
          </Col>
        </Row>
      </Layout>
    </>
  );
};
export default ResetPassword;
